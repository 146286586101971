:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $colors {
    --bs-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --bs-#{$color}: #{$value};
  }

  // Use `inspect` for lists so that quoted items keep the quotes.
  // See https://github.com/sass/sass/issues/2383#issuecomment-336349172
  --bs-font-sans-serif: "AvenirNextPForBBG-Regular";//#{inspect($font-family-sans-serif)};
  --bs-font-monospace: "AvenirNextPForBBG-Regular";//#{inspect($font-family-monospace)};
}
