.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > select,
.form-label-group > label {
  height: 3.125rem;
  padding: .75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  //border-radius: .25rem;
  //transition: all .1s ease-in-out;
}

//.form-label-group input::-webkit-input-placeholder {
//  color: transparent;
//}

//.form-label-group input::-ms-input-placeholder {
//  color: transparent;
//}

//.form-label-group input::-moz-placeholder {
//  color: transparent;
//}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group select {
  padding-top: 1.25rem;
  padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group select ~ label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  //.form-label-group input::-ms-input-placeholder {
  //  color: #777;
  //}
}
