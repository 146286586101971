.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  position: absolute;
  top: .8em;
  right: 6px;
  transform: rotate(-90deg);
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-right: .1rem;
  margin-left: .1rem;
}

.dropdown-submenu a {
  color: $dropdown-link-color;
}
